import request from '@/request'
const insert = (params) => { //新增更新字典
    return request({
        url: '/contact/insert',
        method: 'POST',
        data:params
    });
}
const investment = (params) => { //招商
    return request({
        url: '/index/advertisement',
        method: 'POST',
        data:params
    });
}
export {
    insert,investment
}