<template>
  <div class="caseBox">
    <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
      <swiper-slide
        class="swiperItem"
        v-for="(item, index) in list"
        :key="index"
      >
        <m-case :item="item" />
      </swiper-slide>
    </swiper>
    <div class="nav-prev" slot="button-prev">
      <img src="../../images/column_5_prev.png" />
    </div>
    <div class="nav-next" slot="button-next">
      <img src="../../images/column_5_next.png" />
    </div>
  </div>
</template>

<script>
import mCase from '@/components/mCase'
import { caseList } from '@/api/common'

export default {
  props: ['navId'],
  components: {
    mCase,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.nav-next',
          prevEl: '.nav-prev',
        },
        slidesPerView: 4,
        spaceBetween: 16,
      },
      list: [],
    }
  },
  created() {
    this.navId = this.$route.query.navId
  },
  mounted() {
    this.getCaseList()
  },
  methods: {
    async getCaseList() {
      try {
        let { data } = await caseList({
          category_id: this.navId,
        })
        this.list = data.list
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.caseBox {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  .swiperItem {
    width: 313px;
    & /deep/ .image {
      height: 250px;
    }
  }
  .nav-prev,
  .nav-next {
    width: 51px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .nav-prev {
    left: -51px;
  }
  .nav-next {
    right: -51px;
  }
  // .swiper-button-prev,
  // .swiper-button-next {
  // 	width: 52px;
  // 	height: 52px;
  // 	background-color: #f5c02f;
  // 	color: #fff;
  // 	--swiper-navigation-size: 25px;
  // 	border-radius: 26px;
  // }
  // .swiper-button-prev {
  // 	left: -90px;
  // }
  // .swiper-button-next {
  // 	right: -90px;
  // }
}
</style>
