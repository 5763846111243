export const platformList = [
  {
    img: require('../../images/platform/platform-1.png'),
    title: '功能定制',
    desc:
      '根据您的实际需求定制您所想要的功能，以实现功能体验完全贴近用户的微信公众平台。',
  },
  {
    img: require('../../images/platform/platform-2.png'),
    title: '接口定制',
    desc:
      '可开发各种高级接口，定制如： ERP,CRM,OA.等第三方平台或已有平台接口。',
  },
  {
    img: require('../../images/platform/platform-3.png'),
    title: '设计定制',
    desc:
      '可开发各种高级接口，定制如： ERP,CRM,OA.等第三方平台或已有平台接口。',
  },
  {
    img: require('../../images/platform/platform-4.png'),
    title: '个性定制',
    desc:
      '可以完全按照您的想法和思路定制您所想要的微信公众平台，想您所想定您所需。',
  },
]

export const partialFunctionList = [
  {
    title: '微官网',
    img: require('../../images/partialFunction/icon1.png'),
    activeImg: require('../../images/partialFunction/icon1-active.png'),
  },
  {
    title: '微商城',
    img: require('../../images/partialFunction/icon2.png'),
    activeImg: require('../../images/partialFunction/icon2-active.png'),
  },
  {
    title: '微活动',
    img: require('../../images/partialFunction/icon3.png'),
    activeImg: require('../../images/partialFunction/icon3-active.png'),
  },
  {
    title: '微预订',
    img: require('../../images/partialFunction/icon4.png'),
    activeImg: require('../../images/partialFunction/icon4-active.png'),
  },
  {
    title: '微支付',
    img: require('../../images/partialFunction/icon5.png'),
    activeImg: require('../../images/partialFunction/icon5-active.png'),
  },
  {
    title: '微办公',
    img: require('../../images/partialFunction/icon6.png'),
    activeImg: require('../../images/partialFunction/icon6-active.png'),
  },
  {
    title: '微CRM',
    img: require('../../images/partialFunction/icon7.png'),
    activeImg: require('../../images/partialFunction/icon7-active.png'),
  },
  {
    title: '小程序',
    img: require('../../images/partialFunction/icon8.png'),
    activeImg: require('../../images/partialFunction/icon8-active.png'),
  },
  {
    title: '微社区',
    img: require('../../images/partialFunction/icon9.png'),
    activeImg: require('../../images/partialFunction/icon9-active.png'),
  },
  {
    title: '微客服',
    img: require('../../images/partialFunction/icon10.png'),
    activeImg: require('../../images/partialFunction/icon10-active.png'),
  },
  {
    title: '微会员',
    img: require('../../images/partialFunction/icon11.png'),
    activeImg: require('../../images/partialFunction/icon11-active.png'),
  },
  {
    title: '微名片',
    img: require('../../images/partialFunction/icon12.png'),
    activeImg: require('../../images/partialFunction/icon12-active.png'),
  },
  {
    title: '微红包',
    img: require('../../images/partialFunction/icon13.png'),
    activeImg: require('../../images/partialFunction/icon13-active.png'),
  },
  {
    title: '微房产',
    img: require('../../images/partialFunction/icon14.png'),
    activeImg: require('../../images/partialFunction/icon14-active.png'),
  },
  {
    title: '微点餐',
    img: require('../../images/partialFunction/icon15.png'),
    activeImg: require('../../images/partialFunction/icon15-active.png'),
  },
  {
    title: '微统计',
    img: require('../../images/partialFunction/icon16.png'),
    activeImg: require('../../images/partialFunction/icon16-active.png'),
  },
  {
    title: '微服务',
    img: require('../../images/partialFunction/icon17.png'),
    activeImg: require('../../images/partialFunction/icon17-active.png'),
  },
  {
    title: '微留言',
    img: require('../../images/partialFunction/icon18.png'),
    activeImg: require('../../images/partialFunction/icon18-active.png'),
  },
  {
    title: '微相册',
    img: require('../../images/partialFunction/icon19.png'),
    activeImg: require('../../images/partialFunction/icon19-active.png'),
  },
  {
    title: '微汽车',
    img: require('../../images/partialFunction/icon20.png'),
    activeImg: require('../../images/partialFunction/icon20-active.png'),
  },
  {
    title: '微酒店',
    img: require('../../images/partialFunction/icon21.png'),
    activeImg: require('../../images/partialFunction/icon21-active.png'),
  },
  {
    title: '微政务',
    img: require('../../images/partialFunction/icon22.png'),
    activeImg: require('../../images/partialFunction/icon22-active.png'),
  },
  {
    title: '微医疗',
    img: require('../../images/partialFunction/icon23.png'),
    activeImg: require('../../images/partialFunction/icon23-active.png'),
  },
  {
    title: '微生活',
    img: require('../../images/partialFunction/icon24.png'),
    activeImg: require('../../images/partialFunction/icon24-active.png'),
  },
]
