<template>
  <div class="investment" v-if="invest && invest.length > 0">
    <div class="invesBox center">
      <!-- 招商横幅 -->
      <img :src="invest" />
    </div>
  </div>
</template>

<script>
import { investment } from '@/api/about'
export default {
  created() {
    this.getInvestment()
  },
  data() {
    return {
      invest: null,
    }
  },
  methods: {
    async getInvestment() {
      try {
        //  debugger
        let res = await investment()
        this.invest = res.show == 1 ? res.data : []
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.investment {
  width: 100%;
  // img{
  //     max-width: 100%;
  //     max-height: 100%;
  // }
  .invesBox {
    width: 68.125%;
    // background: #929292;
    position: relative;
    // font-size: 32px;
    // line-height: 32px;
    margin: 0 auto;
    color: #ffffff;
    // padding: 10px 0 12px;
    // img{
    //     position: absolute;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
  }

  padding: 40px 0 70px;
  text-align: center;
  background: #ffffff;
}
</style>
