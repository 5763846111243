<template>
  <div class="wechat">
    <div class="banner1">
      <img src="../../images/wechat-banner1.webp" />
      <img class="btn" src="../../images/moreCaseBtn.png" @click="onClickBtn" />
    </div>
    <section class="platform">
      <div class="header">
        <h2 class="title">深度高端定制微信平台</h2>
        <p class="desc">
          让您的微信公众平台更具吸引力，针对行业及企业的个性化需求，定制开发利用最大化，
        </p>
        <p class="desc">用户体验最优化，生产管理做到精益求精</p>
      </div>
      <div class="list">
        <div class="item" v-for="item in platformList" :key="item.title">
          <div class="left">
            <img :src="item.img" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="partialFunction">
      <div class="header">
        <h2 class="title">部分功能展示</h2>
      </div>
      <div class="list">
        <div
          class="item"
          :class="{ active: activeFuncIndex == index }"
          v-for="(item, index) in partialFunctionList"
          :key="item.text"
          @mouseenter="onIndustryMouseenter(index)"
          @mouseleave="onIndustryMouseleave(index)"
        >
          <div class="icon">
            <img :src="activeFuncIndex == index ? item.activeImg : item.img" />
          </div>
          <span class="text">{{ item.title }}</span>
        </div>
      </div>
    </section>
    <section class="caseShow">
      <div class="header">
        <h2 class="title">案列展示</h2>
      </div>
      <div class="box">
        <m-case-box :navId="navId" />
      </div>
    </section>
    <investment></investment>
  </div>
</template>

<script>
import { caseList } from '@/api/common'

import { platformList, partialFunctionList } from './data'
import mCaseBox from '@/components/mCaseBox'
import investment from '@/components/investment'
export default {
  components: {
    mCaseBox,
    investment,
  },
  data() {
    return {
      platformList,
      partialFunctionList,

      activeFuncIndex: -1,

      navId: '',
    }
  },
  created() {
    this.navId = this.$route.query.navId
  },
  mounted() {
    this.getCaseList()
  },
  methods: {
    async getCaseList() {
      try {
        let { data } = await caseList({
          category_id: this.navId,
        })
        this.caseList = data.list
      } catch (error) {
        console.error(error)
      }
    },
    onIndustryMouseenter(index) {
      this.activeFuncIndex = index
    },
    onIndustryMouseleave() {
      this.activeFuncIndex = -1
    },
    onClickBtn() {
      this.$router.push({ name: 'case', query: { navId: '' } })
    },
  },
}
</script>

<style lang="scss">
.wechat {
  .banner1 {
    width: 100%;
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
    .btn {
      cursor: pointer;
      position: absolute;
      width: 14%;
      bottom: 10%;
      left: 26%;
    }
  }
  .platform {
    width: 100%;
    height: 798px;
    background-image: url('../../images/wechat-banner2.webp');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
    .header {
      padding: 57px 0 50px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background-color: #373737;
          left: 50%;
          bottom: 10px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
      .desc {
        font-size: 16px;
        line-height: 26px;
        color: #383838;
      }
    }
    .list {
      width: 1180px;
      margin: 0 auto;
      .item {
        height: 158px;
        background: #f5bf2f;
        box-shadow: 0px 7px 3px 0px rgba(151, 150, 153, 0.28);
        border-radius: 17px;
        display: inline-flex;
        margin: 0 25px 30px;
        overflow: hidden;

        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          width: 156px;
          > img {
            width: 60px;
            height: 60px;
          }
        }
        .right {
          width: 330px;
          text-align: left;
          padding: 0 21px 0 26px;
          .title {
            font-size: 22px;
            font-weight: bold;
            color: #ffffff;
            line-height: 38px;
            padding-top: 20px;
          }
          .desc {
            font-size: 16px;
            color: #ffffff;
            line-height: 24px;
            padding-top: 10px;
          }
        }
      }
    }
  }
  .partialFunction {
    width: 100%;
    height: 972px;
    background-image: url('../../images/wechat-banner3.webp');
    background-position: center center;
    background-size: 100% 100%;
    .header {
      padding: 57px 0 50px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background-color: #373737;
          left: 50%;
          bottom: 10px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
    .list {
      width: 975px;
      margin: 45px auto 0;
      text-align: left;
      .item {
        width: 122px;
        height: 122px;
        background-color: #fff;
        box-shadow: 1px 6px 4px 0px rgba(152, 151, 153, 0.28);
        border-radius: 18px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 20px 45px;
        cursor: pointer;
        transition: all 0.5s;
        &.active {
          background-color: #f5c030;
          .text {
            color: #fff;
          }
        }
        .icon {
          width: 60px;
          height: 60px;
        }
        .text {
          font-size: 16px;
          font-weight: 700;
          color: #565656;
          margin-top: 10px;
        }
      }
    }
  }
  .caseShow {
    padding: 0 0 58px;
    background-color: #f4f5f9;
    .header {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: #fff;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 2px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
    }
    .box {
      padding-top: 36px;
    }
  }
}
</style>
